import { isArray } from "lodash";
import { useTranslation } from "react-i18next";

export default function useHandler(toggleDialog) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  function getErrMsg(msg) {
    if (!msg) {
      return t("error.unknow");
    }

    if (typeof msg === "string") {
      return msg;
    }

    const errors = msg?.error?.errors || [];

    const errorStringArray =
      errors && isArray(errors)
        ? errors.map(
            ({ message, errorCode }) => `${message}(${errorCode})<br/>`
          )
        : [];

    const errorString = errorStringArray.toString().replace(/[,]/g, "");

    return errorString || t("error.unknow");
  }

  function ErrorHandler(err, props) {
    const type = "error";

    const message = getErrMsg(err);

    if (typeof message !== "string") {
      toggleDialog({ title: t("error.unknow"), type });
      return;
    }

    if (message.includes("100001")) {
      toggleDialog({ title: t("error.passwordError"), type });
      return;
    }

    toggleDialog({ ...props, title: message, type });
  }

  return {
    ErrorHandler,
  };
}
