import styled from "styled-components";
import { devices } from "../constants/breakpoints";

export const StyledP = styled.p`
  color: ${(props) =>
    props.theme[props.color]
      ? `${props.theme[props.color]}`
      : props.theme.black};
  font-size: ${({ fontSize }) => fontSize && ` ${fontSize}px`};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin: ${(props) => (props.$noMargin ? 0 : props.margin)};
  text-align: ${({ textAlign }) => textAlign};
  white-space: ${({ whiteSpace }) => whiteSpace};

  @media ${devices.mobile} {
    font-size: ${({ sx }) => sx?.fontSize && `${sx?.fontSize}px`};
    margin: ${(props) => (props?.sx?.$noMargin ? 0 : props?.sx?.margin)};
    text-align: ${({ sx }) => sx?.textAlign};
  }
`;

export const StyledSpan = styled.span`
  color: ${(props) =>
    props.theme[props.color]
      ? `${props.theme[props.color]}`
      : props.theme.black};
  font-size: ${({ fontSize }) => fontSize && `${fontSize}px`};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin: ${({ margin }) => margin};

  @media ${devices.mobile} {
    font-size: ${({ sx }) => `${sx?.fontSize}px`};
    margin: ${({ sx }) => sx?.margin};
  }
`;

export const FlexDiv = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  align-items: ${({ alignItems }) => alignItems || "center"};
  justify-content: ${({ justifyContent }) => justifyContent};

  @media ${devices.mobile} {
    font-size: ${({ sx }) => sx?.fontSize};
    margin: ${({ sx }) => sx?.margin};
    padding: ${({ sx }) => sx?.padding};
    justify-content: ${({ sx }) => sx?.justifyContent};
    align-items: ${({ sx }) => sx?.alignItems};
    flex-direction: ${({ sx }) => sx?.flexDirection};
  }
`;
