import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import { LayoutContainer } from "./Layout.styles";
import Header from "./Header";
// import SlideMenu from "./SlideMenu";
import AppModel from "../../models/appModel";
import Footer from "./Footer";
import Menu from "./Menu";
import Dialog from "../Dialog";
import LoadingView from "../LoadingView";

export default function Layout() {
  const { version, loading } = useContext(AppModel.Context);
  return (
    <LayoutContainer>
      {version !== "desktop" && <Header />}
      <Menu />
      <Outlet />
      {version !== "desktop" && <Footer />}
      <Dialog />
      {loading && <LoadingView />}
    </LayoutContainer>
  );
}
