import React, { useContext } from "react";
import { useTransition } from "@react-spring/web";
import {
  Container,
  ButtonRow,
  BottomDiv,
  TopDiv,
  BgImg,
  StyledLink,
  LionImg,
  ProfileIcon,
  DefaultPrifleIcon,
  LogoutButton,
  IconRow,
} from "./styles";
import AppModel from "../../../models/appModel";
import Button from "../../Button";
import icon from "../../../assets/images/whiteIcon.png";
import menuBg from "../../../assets/images/menuBg.png";
import lion from "../../../assets/images/lion01.png";
import {
  SIGNUP_PATH,
  LOGIN_PATH,
  REWRADS_PATH,
} from "../../../constants/paths";
import UserModel from "../../../models/userModel";
import defaultIcon from "../../../assets/images/defaultIcon.png";
import logoutIcon from "../../../assets/images/logout.png";
import { StyledP } from "../../../base/BaseStyle";

export default function Menu() {
  const { toggleMenu, showMenu, navigate, t } = useContext(AppModel.Context);
  const { user, token, logout } = useContext(UserModel.Context);

  const { displayName = "" } = user || {};

  const transitions = useTransition(showMenu, {
    from: { opacity: 0, y: -999 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: -999 },
  });

  function handleRedirect(path) {
    navigate(path);
    toggleMenu(false);
  }

  return (
    <>
      {transitions(
        (props, item) =>
          item && (
            <Container style={props}>
              <TopDiv>
                <BgImg src={menuBg} />

                {token ? (
                  <IconRow>
                    <ProfileIcon>
                      <DefaultPrifleIcon src={defaultIcon} alt="" />
                      <LogoutButton type="button" onClick={() => logout()}>
                        <img src={logoutIcon} alt="" />
                      </LogoutButton>
                    </ProfileIcon>

                    <StyledP
                      color="primary"
                      fontSize="25"
                      fontWeight="bold"
                      $noMargin
                    >
                      你好！
                    </StyledP>
                    <StyledP
                      color="primary"
                      fontSize="25"
                      $noMargin
                      style={{ lineHeight: 1 }}
                    >
                      {displayName}
                    </StyledP>
                  </IconRow>
                ) : (
                  <ButtonRow>
                    <Button
                      sx={{ marginTop: 20 }}
                      tablet={{ width: 300 }}
                      onClick={() => handleRedirect(SIGNUP_PATH)}
                    >
                      <img src={icon} alt="" />
                      {t("signUp.title")}
                    </Button>

                    <Button
                      variant="outline"
                      tablet={{
                        width: 300,
                        marginTop: 20,
                        padding: "20px 60px",
                      }}
                      sx={{ padding: "15px 60px" }}
                      onClick={() => handleRedirect(LOGIN_PATH)}
                    >
                      {t("login.title")}
                    </Button>
                  </ButtonRow>
                )}
              </TopDiv>
              <BottomDiv>
                {token && (
                  <StyledLink onClick={() => handleRedirect(REWRADS_PATH)}>
                    {t("menu.ownership")}
                  </StyledLink>
                )}
                <StyledLink>{t("menu.about")}</StyledLink>
                <StyledLink>{t("menu.character")}</StyledLink>
                <StyledLink>{t("menu.nft")}</StyledLink>
                <StyledLink>{t("menu.story")}</StyledLink>
                <StyledLink>{t("menu.tnc")}</StyledLink>
                <StyledLink>{t("menu.privacyPolicy")}</StyledLink>
              </BottomDiv>

              <LionImg src={lion} />
            </Container>
          )
      )}
    </>
  );
}
