import React from "react";
import { PrimaryButton, OutlineButton, TransparentButton } from "./style";
import Loader from "../Loader";

export default function Button({
  variant,
  type = "button",
  children,
  loading = false,
  ...props
}) {
  if (variant === "outline") {
    return (
      <OutlineButton {...props} type={type}>
        {children}
      </OutlineButton>
    );
  }

  if (variant === "transparent") {
    return (
      <TransparentButton {...props} type={type}>
        {children}
      </TransparentButton>
    );
  }

  return (
    <PrimaryButton
      {...props}
      type={type}
      disabled={loading ? true : props.disabled}
    >
      {children}
      {loading && <Loader />}
    </PrimaryButton>
  );
}
