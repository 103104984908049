import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  inset: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const LoadingImage = styled.img`
  height: 500px;
  width: auto;
`;
