import AppModel from "./appModel";
import DialogModel from "./dialogMoel";
import UserModel from "./userModel";

const Models = [UserModel, AppModel, DialogModel];

const BindModels = (ViewComponent) => {
  let bindedComponent = ViewComponent;
  Models.forEach((model) => {
    bindedComponent = model.withProvider(bindedComponent);
  });
  return bindedComponent;
};

export { BindModels };
export default Models;
